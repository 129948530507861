import { useState, useEffect } from 'react'
import { getClientLastVisitReportImages } from '../../services/visitReportsService'
import { ILastVisitReport } from '../../interfaces/IVisitReports'

export const useClientLastVisitReportImages = (clientId: number) => {
  const [data, setData] = useState<ILastVisitReport | null>(null)

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getClientLastVisitReportImages(clientId)
        setData(result)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [clientId])

  return data
}
