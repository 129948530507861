import { IRequest } from '../interfaces/IRequest'
import { api, getAuthHeader } from '../utils/api'
import { IUsersOptions } from '../interfaces/IUsers'
import {
  ICheckDuplicate,
  ILastVisitReport,
  IPlvData,
  IUploadImage,
  IVisitReportForm,
} from '../interfaces/IVisitReports'
import {
  prepareContactTypesOptions,
  prepareOptions,
} from '../utils/visitReports'
import { API_PATHS } from '../enums/apiPaths'
import { BASE_BACKEND_URL } from '../enums/common'
import {
  CHECK_DUPLICATE_IMAGE_FORM,
  UPLOAD_IMAGE_FORM,
} from '../constants/form'
import { IArrayOptions } from '../interfaces/IAppointments'
import { IClientEventData } from '../interfaces/IClientDetails'

export async function createNewVisitReport(
  formData: IVisitReportForm
): Promise<number> {
  const params: IRequest = {
    endpoint: API_PATHS.visitReport,
    method: 'POST',
    body: JSON.stringify(formData),
  }
  const {
    body: { id },
  } = await api(params)

  return id
}

export async function getContactOutcome(): Promise<IUsersOptions[]> {
  const params: IRequest = {
    endpoint: API_PATHS.contactOutcomes,
    method: 'GET',
  }

  const {
    body: { data },
  } = await api(params)

  return prepareOptions(data)
}

export async function getContactTypes(): Promise<IArrayOptions[]> {
  const params: IRequest = {
    endpoint: API_PATHS.contactTypes,
    method: 'GET',
  }

  const {
    body: { data },
  } = await api(params)

  return prepareContactTypesOptions(data)
}

export async function getPlvList(): Promise<IPlvData[]> {
  const params: IRequest = {
    endpoint: API_PATHS.plvList,
    method: 'GET',
  }

  const {
    body: { data },
  } = await api(params)

  return data
}

export async function getClientLastVisitReportImages(
  clientId: number,
  queryParam?: string
): Promise<ILastVisitReport> {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.lastVisitReport}${
      queryParam ? `?${queryParam}` : ''
    }`,
    method: 'GET',
  }

  const {
    body: { data, lastVisitReportDate },
  } = await api(params)

  const newData = {
    ...data,
    lastVisitReportDate,
  }

  return newData
}

export async function uploadImage(data: IUploadImage) {
  const formData = new FormData()
  UPLOAD_IMAGE_FORM.forEach((key) => {
    if (data[key as keyof IUploadImage]) {
      formData.append(key, data[key as keyof IUploadImage] as string | Blob)
    }
  })

  const params: IRequest = {
    endpoint: `${API_PATHS.file}${API_PATHS.upload}`,
    baseURL: BASE_BACKEND_URL,
    method: 'POST',
    body: formData,
    headers: getAuthHeader(),
  }

  return api(params)
}

export async function checkDuplicateFile(
  imageData: ICheckDuplicate
): Promise<any> {
  const formData = new FormData()
  CHECK_DUPLICATE_IMAGE_FORM.forEach((key) => {
    if (imageData[key as keyof ICheckDuplicate]) {
      formData.append(
        key,
        imageData[key as keyof ICheckDuplicate] as string | Blob
      )
    }
  })

  const params: IRequest = {
    endpoint: `${API_PATHS.checkDuplicateFile}`,
    baseURL: BASE_BACKEND_URL,
    method: 'POST',
    body: formData,
    headers: getAuthHeader(),
  }

  return api(params)
}

export async function getNextAppointment(
  clientId: number
): Promise<IClientEventData[]> {
  const params: IRequest = {
    endpoint: `${API_PATHS.clients}/${clientId}${API_PATHS.nextAppointment}`,
    method: 'GET',
  }

  const { body: data } = await api(params)

  return data
}
