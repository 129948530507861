import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { IBaseTab } from '../../../interfaces/IVisitReports'
import RadioButtonGroup from '../../form/RadioButtonGroup'
import {
  AVERAGE_PASSAGES_PER_DAY,
  LOCALISATION,
  PHARMACYSIZE,
  VISIT_ACTION_TYPE_1,
} from '../../../constants/visitReport'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'

export const VisitReportFourthPanel: FC<IBaseTab> = ({
  data: { localisation, pharmacySize, averagePassagesPerDay },
}) => {
  const { register, watch, errors } = useFormContext()
  const contactTypeId = watch('contactTypeId')
  const fieldRequired = contactTypeId === VISIT_ACTION_TYPE_1

  return (
    <>
      <div className="mb25">
        <DynamicFormattedMessage
          id="form.field.pharmacyTypology.label"
          tag="h3"
          className="textPrimary fontBold mb15 mb45md"
        />
        <div className="dFlex row">
          <div className="colSm6 colLg5 pb2">
            <RadioButtonGroup
              name="localisation"
              options={LOCALISATION}
              register={register({ required: fieldRequired })}
              error={errors.localisation}
              defaultSelectedValue={localisation}
            />
          </div>
          <div className="colSm5">
            <RadioButtonGroup
              name="pharmacySize"
              options={PHARMACYSIZE}
              register={register({ required: fieldRequired })}
              error={errors.pharmacySize}
              defaultSelectedValue={pharmacySize}
            />
          </div>
        </div>
      </div>
      <div className="mb5 mt5md">
        <DynamicFormattedMessage
          id="form.field.PharmacyAttendance.label"
          tag="h3"
          className="textPrimary fontBold mb2 mb45md"
        />
        <RadioButtonGroup
          name="averagePassagesPerDay"
          options={AVERAGE_PASSAGES_PER_DAY}
          register={register({ required: fieldRequired })}
          error={errors.averagePassagesPerDay}
          defaultSelectedValue={averagePassagesPerDay}
        />
      </div>
    </>
  )
}
