export const BASE_BACKEND_URL: string | undefined =
  process.env.REACT_APP_BACKEND_URL
export const API_VERSION: string | undefined = process.env.REACT_APP_API_VERSION

export const START_DATE_OF_PREVIOUS_ORDER_HISTORY: string | undefined =
  process.env.REACT_APP_START_DATE_OF_PREVIOUS_ORDER_HISTORY

/**
 * add env variable REACT_APP_ENABLE_BLUR=true (or false) in .env
 */
export const BLUR_ENABLED: string | undefined =
  process.env.REACT_APP_ENABLE_BLUR

/**
 * add env variable REACT_APP_MISSION_NAME eg: REACT_APP_MISSION_NAME='Frontline' in .env
 */
export const MISSION_NAME: string | undefined =
  process.env.REACT_APP_MISSION_NAME

// types of errors
export const DANGER = 'danger'
export const WARNING = 'warning'

// types of error messages
export const UNAUTHORIZED = 'unauthorized'
export const FORBIDDEN = 'forbidden'
export const NOT_FOUND = 'notFound'
export const REQUEST_TIMEOUT = 'requestTimeout'
export const TECHNICAL = 'technical'
export const BAD_GATEWAY = 'badGateway'
export const BAD_REQUEST = 'badRequest'
export const DEFAULT = 'default'

// api status and codes
export const STATUS_BAD_REQUEST = '400'
export const STATUS_UNAUTHORIZED = '401'
export const STATUS_FORBIDDEN = '403'
export const STATUS_NOT_FOUND = '404'
export const STATUS_REQUEST_TIMEOUT = '408'
export const STATUS_TECHNICAL = '500'
export const STATUS_BAD_GATEWAY = '502'

export const CODE_1003 = '1003'
export const CODE_1011 = '1011'
export const CODE_1014 = '1014'
export const CODE_1017 = '1017'

export const EXTERNAL_STORAGE_UPLOAD_FAILED_CODE = `${STATUS_BAD_GATEWAY}-${CODE_1011}`
export const EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE = `${BAD_GATEWAY}.${CODE_1011}`
export const STATUS_UNAUTHORIZED_CODE = `${STATUS_UNAUTHORIZED}-${CODE_1003}`
export const STATUS_USER_LOCATION_MISSING_CODE = `${STATUS_BAD_REQUEST}-${CODE_1014}`
export const STATUS_USER_LOCATION_MISSING_MESSAGE = `${BAD_REQUEST}.${CODE_1014}`
export const IMAGE_IS_DUPLICATE = `${BAD_REQUEST}.${CODE_1017}`

export const ERROR_MESSAGES_BY_STATUS_OR_CODE: { [key: string]: string } = {
  [STATUS_UNAUTHORIZED]: UNAUTHORIZED,
  [STATUS_UNAUTHORIZED_CODE]: UNAUTHORIZED,
  [STATUS_FORBIDDEN]: FORBIDDEN,
  [STATUS_NOT_FOUND]: NOT_FOUND,
  [STATUS_REQUEST_TIMEOUT]: REQUEST_TIMEOUT,
  [STATUS_TECHNICAL]: TECHNICAL,
  [EXTERNAL_STORAGE_UPLOAD_FAILED_CODE]: EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  [STATUS_USER_LOCATION_MISSING_CODE]: STATUS_USER_LOCATION_MISSING_MESSAGE,
  [IMAGE_IS_DUPLICATE]: IMAGE_IS_DUPLICATE,
  DEFAULT,
} as const

// pagination
export const ITEMS_PER_PAGE = 20
export const RANGE_DISPLAYED = 5
export const INITIAL_ACTIVE_PAGE = 1
export const INITIAL_OFFSET = 0
export const ENGAGEMENTS_PER_PAGE = 25
export const MAX_ENGAGEMENTS = 500

// startDate and endDate format for requests
export const DATE_REQUEST_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
export const FORM_DATE_REQUEST_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'"
export const DATEPICKER_FORMAT = 'dd-MM-yyyy'
export const DATEPICKER_FILTERS_FORMAT = 'dd/MM/yyyy'
export const DATEPICKER_TIME_FORMAT = 'dd-MM-yyyy HH:mm:ss'
export const EVENTS_DATETIME_FORMAT = 'dd.MM.yyyy | HH:mm'
export const REPORT_DATETIME_FORMAT = 'dd.MM.yyyy'
export const CURRENT_DAY_DATETIME_FORMAT = 'dd.MM.yyyy'
export const HISTORY_DATE_FORMAT = 'dd.MM.yyyy'
export const HISTORY_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const TODAY_APPOINTMENT_TIME_FORMAT = 'HH:mm'
export const FILE_DOWNLOAD_TIME_FORMAT = 'yyyyMMdd'
export const NO_DATE = '-'
export const DD_MM_YYYY_DATE_FORMAT = 'dd/MM/yyyy'
export const NEXT_VISIT_REPORT_DATE_FORMAT = 'dd-MM-yyyy HH:mm'

// roles
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_USER = 'ROLE_USER'

export const ADMIN_USER = 'admin'
export const REGULAR_USER = 'kam'

// routes
export const GROUPMENTS_ROUTE = 'groupments'
export const POTENTIAL_ROUTE = 'potentials'
export const TYPE_CIBLE_1_ROUTE = 'target-1-types'
export const TYPE_CIBLE_2_ROUTE = 'target-2-types'
export const STRATEGO_ROUTE = 'target-3-types'
export const MARQUE_ROUTE = 'product-lines'

// statuses
export const STATUS = {
  DANGER: 'danger',
  SUCCESS: 'success',
  PENDING: 'pending',
  IDLE: 'idle',
} as const

// size - number of items to return
export const ITEMS_SIZE = '1000'

// 30 min in milliseconds
export const THIRTY_MIN = 1800000

// href type for anchors
export const PHONE = 'tel:'
export const EMAIL = 'mailto:'
export const MAP = 'https://www.google.com/maps/search/?api=1&query='

// RangeStepInput default, min, max and step
export const RANGE_INPUT_DEFAULT = 1
export const RANGE_INPUT_MIN = 0.5
export const RANGE_INPUT_MAX = 8
export const RANGE_INPUT_STEP = 0.5
export const RANGE_INPUT_MINUTES = 60

// Legend colors
const DARK_GREEN = 'Darkgreen'
const RED = 'Red'
const DARK_RED = 'Darkred'
const PURPLE = 'Purple'
const YELLOW = 'Yellow'
const GRAY = 'Gray'
const BLUE = 'Blue'
const GREEN = 'Green'
const PINK = 'Pink'
const DARK_BLUE = 'Darkblue'

export const LEGEND_COLORS = {
  DARK_GREEN,
  RED,
  DARK_RED,
  PURPLE,
  YELLOW,
  GRAY,
  BLUE,
  GREEN,
  PINK,
  DARK_BLUE,
}

export const MAP_LEGEND = [
  LEGEND_COLORS.DARK_GREEN,
  LEGEND_COLORS.DARK_RED,
  LEGEND_COLORS.YELLOW,
  LEGEND_COLORS.DARK_BLUE,
  LEGEND_COLORS.BLUE,
  LEGEND_COLORS.GREEN,
]

export const AGENDA_LEGEND = [
  LEGEND_COLORS.PURPLE,
  LEGEND_COLORS.DARK_GREEN,
  LEGEND_COLORS.DARK_BLUE,
  LEGEND_COLORS.PINK,
]

export const CACHE_USAGE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
} as const

export const CACHE_USAGE_COLOR = {
  SUCCESS: 'SecondaryLight',
  WARNING: 'Warning',
  DANGER: 'Danger',
} as const

export const PHOTO_FILE_NAME = 'camera.jpeg'
export const COMPRESSED_PHOTO_FILE_NAME = 'compressed.jpeg'

export const FRONT_CAMERA = 'user'
export const BACK_CAMERA = 'environment'

export const OBJECT_STORE = 'requests'

export const OFFLINE_MESSAGE = {
  invalidToken: 'INVALID_TOKEN',
  deleteCacheOpened: 'DELETE_CACHE_OPEN',
  onSyncLoading: 'ONSYNC_LOADING',
  onSyncError: 'ONSYNC_ERROR',
  onSyncStoreDone: 'ONSYNC_STORE_DONE',
  onSyncImageStoreDone: 'ONSYNC_IMAGE_STORE_DONE',
}

export const OFFLINE_EVENT_TAG = {
  backgroundForceSync: 'BACKGROUND_FORCE_SYNC',
}

export const WYSIWYG_INPUT_MAX_LENGTH = 2000

export const APPOINTMENT_ACTION = {
  EDIT: 'edit',
  VIEW: 'view',
} as const

// Multiyear versioning
export const VTesting = 'vTesting'
export const V1 = 'v1'
export const V2 = 'v2'
export const V3 = 'v3'
export const V4 = 'v4'

/**
 * when mapping for directory versioning for VIEW VISIT REPORTS
 * for simplicity it would be better to have the following structure for mapping
 * versionNumber:directoryName where directoryName will be the version number
 */
export const VISIT_REPORT_VERSIONS = {
  vTesting: VTesting,
  v1: V1,
  v2: V2,
  v3: V3,
  v4: V4,
} as const

export const CONTRACTS_MONTH_INDEX = 9

export const DYNAMIC_BUTTONS_INPUT_TYPE = {
  checkbox: 'checkbox',
  radio: 'radio',
} as const

export const FILE_EXTENSION_PDF = '.pdf'
export const FILE_EXTENSION_ICS = '.ics'

export const CLIENT_EMAIL = 'clientEmail'
export const KAM_EMAIL = 'kamEmail'
export const DCR_EMAIL = 'dcrEmail'
export const CONTENT_DATA = 'contentData'

export const SEND_EMAIL_API_TYPE_CONTRACT_PDF = 1
export const SEND_EMAIL_API_TYPE_APPOINTMENT_ICS = 2
export const SEND_EMAIL_API_TYPE_DESTRUCTION_CERTIFICATE = 3

export const FILE_TYPES_BY_SEND_EMAIL_API_TYPE = {
  [`${SEND_EMAIL_API_TYPE_CONTRACT_PDF}`]: FILE_EXTENSION_PDF,
  [`${SEND_EMAIL_API_TYPE_APPOINTMENT_ICS}`]: FILE_EXTENSION_ICS,
}

export const PERMISSION_GRANTED = 'granted'
export const PERMISSION_DENIED = 'denied'
export const PERMISSION_PROMPT = 'prompt'
export const GEOLOCATION = 'geolocation'
