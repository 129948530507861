import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory, generatePath } from 'react-router-dom'
import { useState, Dispatch, SetStateAction } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ILastVisitReport,
  IVisitReportForm,
  TVisitReportResponse,
} from '../../interfaces/IVisitReports'
import {
  prepareRequestForUploadImage,
  prepareRequestForNewVisitReport,
  resetVisitReportFields,
} from '../../utils/visitReports'
import {
  createNewVisitReport,
  uploadImage,
} from '../../services/visitReportsService'
import {
  API_VERSION,
  BASE_BACKEND_URL,
  EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE,
  STATUS,
} from '../../enums/common'
import { newVisitReportActions } from '../../store/reducers/newVisitReportReducer'
import { mockNewVisitReportFormInitialState } from '../../__mocks__/dataMock'
import { ROUTES } from '../../enums/routes'
import { statusNetworkReducerActions } from '../../store/reducers/statusNetworkReducer'
import { statusNetworkSelector } from '../../store/selectors'
import { addDataInIndexedDb } from '../../utils/offline'
import { API_PATHS } from '../../enums/apiPaths'
import { handleError } from '../../utils/api'
import { ResponseError } from '../../models/errors/ResponseError'
import {
  IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE,
  VISIT_ACTION_TYPE_2,
  VISIT_ACTION_TYPE_ID,
  VISIT_REPORT_IMAGE_LIST,
} from '../../constants/visitReport'

export const useVisitReportFormHook = (
  clientId: number,
  setVisitDate: any,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  state: Location,
  images: ILastVisitReport
) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const history = useHistory()
  const form = useForm<IVisitReportForm>()
  const [formState, setFormState] = useState<TVisitReportResponse>({
    status: STATUS.IDLE,
    message: '',
    messageCode: '',
  })
  const { hasNetwork } = useSelector(
    statusNetworkSelector.getStatusNetworkValue
  )

  const { reset, setValue, getValues } = form
  const isTabDisabled = getValues(VISIT_ACTION_TYPE_ID) === VISIT_ACTION_TYPE_2

  const handleImagesAddToIndexedDb = (
    values: IVisitReportForm,
    timestamp: Date
  ) => {
    VISIT_REPORT_IMAGE_LIST.map(async (type: string, index) => {
      const data = prepareRequestForUploadImage(values, images, type)
      if (!data) return

      const imageKey = +timestamp.getTime() + (index + 1)

      addDataInIndexedDb(
        'visit-report-images',
        {
          image: data,
          timestamp: timestamp.getTime(),
          requestData: {
            url: `${BASE_BACKEND_URL}${API_PATHS.file}${API_PATHS.upload}`,
            method: 'POST',
          },
          imageKey,
        },
        () => {},
        imageKey
      )
    })
  }

  const handleVisitReportAddToIndexedDb = (
    visitReportFormData: any,
    timestamp: Date
  ) => {
    const postData = {
      ...visitReportFormData,
      clientId,
    }
    addDataInIndexedDb(
      'visit-report-background-sync',
      {
        data: postData,
        timestamp: timestamp.getTime(),
        requestData: {
          url: `${BASE_BACKEND_URL}${API_VERSION}${API_PATHS.visitReport}`,
          method: 'POST',
        },
      },
      () => {},
      +timestamp.getTime()
    )
  }

  const resetFormToInitialState = () => {
    // remove all data from form
    resetVisitReportFields(reset, setVisitDate, setValue)
    dispatch(
      newVisitReportActions.setNewVisitReport(
        mockNewVisitReportFormInitialState
      )
    )
  }

  const returnToPreviousPage = () =>
    window.history.state
      ? history.go(-1)
      : history.replace(generatePath(ROUTES.clientDetails, { clientId }))

  const uploadImagesRequest = async (
    values: IVisitReportForm,
    reportId: number
  ) => {
    try {
      await Promise.all(
        VISIT_REPORT_IMAGE_LIST.map(async (type: string) => {
          const data = prepareRequestForUploadImage(values, images, type)
          if (!data) return

          await uploadImage({
            entityId: reportId.toString(),
            ...data,
          })
        })
      ).then(() => {
        resetFormToInitialState()
      })
    } catch (error) {
      const isExternalStorageUploadFailed =
        error instanceof ResponseError &&
        error.hasMessageCode(EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE)
      const messageCode = isExternalStorageUploadFailed
        ? EXTERNAL_STORAGE_UPLOAD_FAILED_MESSAGE
        : IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE

      resetFormToInitialState()
      return history.replace(
        {
          pathname: generatePath(ROUTES.clientDetails, { clientId }),
        },
        {
          ...state,
          uploadImageState: true,
          messageCode,
        }
      )
    }
  }

  const onSubmit = async (values: IVisitReportForm) => {
    setIsModalOpen(false)
    const visitReportFormData = prepareRequestForNewVisitReport(values)
    setFormState({ status: STATUS.PENDING, message: '' })

    if (!hasNetwork) {
      const timestamp = new Date()
      handleVisitReportAddToIndexedDb(visitReportFormData, timestamp)

      if (!isTabDisabled) {
        handleImagesAddToIndexedDb(values as IVisitReportForm, timestamp)
      }
    }

    try {
      const reportId = await createNewVisitReport({
        ...visitReportFormData,
        clientId,
      })

      if (!isTabDisabled) {
        await uploadImagesRequest(values, reportId)
      }

      resetFormToInitialState()
      setIsModalOpen(false)
      setFormState({ status: STATUS.SUCCESS, message: '' })
      returnToPreviousPage()
    } catch (error) {
      if (!hasNetwork) {
        resetFormToInitialState()
        setIsModalOpen(false)
        dispatch(statusNetworkReducerActions.submitedForm())
        return history.go(-1)
      }

      const handledError = handleError(error, intl)
      setFormState(handledError)
    }
  }

  return { form, onSubmit, formState }
}
