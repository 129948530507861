import React, { ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import RadioButton from './RadioButton'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

function RadioButtonGroup({
  name,
  options,
  register,
  error,
  defaultSelectedValue,
  customClass = '',
  wrapperClass = '',
}: {
  name: string
  options: {
    id?: string
    labelId: string
    value: string | number
    noTranslation?: boolean
  }[]
  register: (ref: HTMLInputElement) => void
  error?: FieldError | undefined
  defaultSelectedValue?: string | number
  customClass?: string
  wrapperClass?: string
}): ReactElement {
  return (
    <div className={`radioGroup ${customClass}`}>
      <DynamicFormattedMessage
        tag="label"
        id={`form.field.${name}.label`}
        className="inputLabel"
      />

      {/* eslint-disable react/no-array-index-key */}
      {options.map(({ id, labelId, value, noTranslation }, index) => (
        <RadioButton
          key={`${value}-${index}`}
          name={name}
          labelId={labelId}
          translate={!noTranslation}
          id={id}
          value={value}
          register={register}
          defaultValue={defaultSelectedValue === value}
          values={{}}
          wrapperClass={wrapperClass}
        />
      ))}
      {/* eslint-enable react/no-array-index-key */}

      <ErrorMessage error={error} />
    </div>
  )
}

export default RadioButtonGroup
