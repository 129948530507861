import React, { ReactElement } from 'react'
import { FieldError } from 'react-hook-form'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'
import ErrorMessage from './ErrorMessage'
import CheckboxFromGroup from './CheckboxFromGroup'

function CheckboxGroup({
  name,
  options,
  error,
  register,
  defaultValues,
  customClass = '',
  wrapperClass = '',
}: {
  name: string
  options: {
    id: string
    labelId: string
    value: number
    disabled?: boolean
  }[]
  register: (ref: HTMLInputElement) => void
  defaultValues?: number[]
  error?: FieldError | undefined
  customClass?: string
  wrapperClass?: string
}): ReactElement {
  return (
    <div className={`checkboxGroup ${customClass}`}>
      <DynamicFormattedMessage
        tag="label"
        id={`form.field.${name}.label`}
        className="inputLabel"
      />

      {options.map(({ id, labelId, value, disabled }) => (
        <CheckboxFromGroup
          key={value}
          name={name}
          labelId={labelId}
          id={id}
          value={value}
          register={register}
          defaultValues={defaultValues}
          wrapperClass={wrapperClass}
          customClass="group"
          disabled={disabled}
        />
      ))}

      <ErrorMessage error={error} />
    </div>
  )
}

export default CheckboxGroup
