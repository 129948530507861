import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import { IBaseTab } from '../../../interfaces/IVisitReports'
import {
  BRAND_PRESENCE,
  PLV_USE,
  POSITIONING_OF_PRODUCTS_ON_SHELF,
  TRAINING,
  VISIT_ACTION_TYPE_1,
} from '../../../constants/visitReport'
import RadioButtonGroup from '../../form/RadioButtonGroup'
import SelectInput from '../../form/SelectInput'
import CheckboxGroup from '../../form/CheckboxGroup'
import CheckboxFromGroup from '../../form/CheckboxFromGroup'

export const VisitReportSecondPanel: FC<IBaseTab> = () => {
  const { numberOfTablets, numberOfCourses } = useMemo(() => {
    const generateOptions = (length: number) =>
      Array.from({ length }, (_, index) => ({
        name: index.toString(),
        value: index.toString(),
      }))

    return {
      numberOfTablets: generateOptions(41),
      numberOfCourses: generateOptions(21),
    }
  }, [])

  const { register, errors, watch, setValue } = useFormContext()

  const plvUse = watch('plvUse') || []
  const previousPlvUseValues = useRef(plvUse)

  const numberOfVetoTablets = watch('numberOfVetoTablets')
  const numberOfBiTablets = watch('numberOfBiTablets')

  const parsedVetoTablets = parseFloat(numberOfVetoTablets) || 0
  const parsedBiTablets = parseFloat(numberOfBiTablets) || 0
  const contactTypeId = watch('contactTypeId')
  const fieldRequired = contactTypeId === VISIT_ACTION_TYPE_1

  const percentage =
    parsedBiTablets === 0
      ? 0
      : parsedVetoTablets === 0
      ? 'NA'
      : (parsedBiTablets / parsedVetoTablets) * 100

  const calculateDifference = (arr1: string[], arr2: string[]) =>
    arr1.filter((item) => !arr2.includes(item))

  useEffect(() => {
    const [selectedValue] = calculateDifference(
      plvUse,
      previousPlvUseValues.current
    )
    if (selectedValue === '3') {
      setValue('plvUse', ['3'])
    } else if (
      plvUse.includes('3') &&
      (selectedValue === '1' || selectedValue === '2')
    ) {
      setValue(
        'plvUse',
        plvUse.filter((val: string) => val !== '3')
      )
    }

    previousPlvUseValues.current = plvUse
  }, [plvUse, setValue])

  return (
    <>
      <div className="textPrimary">
        <DynamicFormattedMessage
          id="form.field.VisibilityPLV.label"
          tag="h3"
          className="textPrimary fontBold mb15 mb45md"
        />
        <div className="dFlex row">
          <div className="flexColumn colMd6">
            <div className="mb2">
              <RadioButtonGroup
                name="positioningOfProductsOnShelf"
                options={POSITIONING_OF_PRODUCTS_ON_SHELF}
                register={register({ required: fieldRequired })}
                error={errors.positioningOfProductsOnShelf}
              />
              <CheckboxFromGroup
                name="doubleImplantation"
                id="doubleImplantation"
                labelId="doubleImplantation"
                register={register}
              />
            </div>
            <div className="pl0 mb1">
              <DynamicFormattedMessage
                id="form.field.linearPartCalculationSection.label"
                className="textPrimary mb2"
              />
              <div className="pl2 linearCalculationSection">
                <div className="pl0 mb1 mt1">
                  <SelectInput
                    name="numberOfVetoTablets"
                    id="numberOfVetoTablets"
                    register={register({ required: fieldRequired })}
                    error={errors.numberOfVetoTablets}
                    options={numberOfTablets}
                    defaultPlaceholder
                  />
                </div>
                <div>
                  <SelectInput
                    name="numberOfBiTablets"
                    id="numberOfBiTablets"
                    register={register({ required: fieldRequired })}
                    error={errors.numberOfBiTablets}
                    options={numberOfTablets}
                    defaultPlaceholder
                  />
                </div>
                <div className="mt2">
                  <DynamicFormattedMessage
                    id="form.field.linearPartCalculation.label"
                    className="textPrimary mb2"
                  />
                  {percentage !== null && (
                    <span className="textSecondary fontMedium">
                      {percentage === 'NA' ? 'NA' : `${percentage.toFixed(2)}%`}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flexColumn colMd6">
            <div className="textPrimary mb2">
              <CheckboxGroup
                name="plvUse"
                options={PLV_USE}
                register={register({ required: fieldRequired })}
                error={errors.plvUse}
              />
            </div>
            <div>
              <CheckboxGroup
                name="brandPresence"
                options={BRAND_PRESENCE}
                register={register({ required: fieldRequired })}
                error={errors.brandPresence}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <DynamicFormattedMessage
          id="form.field.trainingSection.label"
          tag="h3"
          className="textPrimary fontBold mt35 mb15 mb45md"
        />
        <div className="dFlex row mb5">
          <div className="colMd6">
            <RadioButtonGroup
              name="numberOfTrainedPeople"
              options={TRAINING}
              register={register({ required: fieldRequired })}
              error={errors.numberOfTrainedPeople}
            />
          </div>
          <div className="colMd5">
            <SelectInput
              name="numberOfCoursesOverLastYear"
              id="numberOfCoursesOverLastYear"
              register={register({ required: fieldRequired })}
              error={errors.numberOfCoursesOverLastYear}
              options={numberOfCourses}
              defaultPlaceholder
            />
          </div>
        </div>
      </div>
    </>
  )
}
