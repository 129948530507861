import {
  IVisitReport,
  IVisitReportForm,
  IVisitReportImages,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import { V4 } from '../enums/common'

export const NO_PLV = 'Aucun PLV'
export const IMAGE_UPLOAD_DEFAULT_ERROR_MESSAGE = 'images'

// This should be changed and increased at each new visit report form fields update
export const VISIT_REPORT_CURRENT_VERSION: TVisitReportCurrentVersion = V4

export const VISIT_ACTION_TYPE_1 = '1'
export const VISIT_ACTION_TYPE_2 = '2'

const visitReportImageInitialState = {
  key: null,
  content: null,
}

export const visitReportInitialState: IVisitReport = {
  id: 0,
  comment: '',
  contactOutcome: '',
  contactPerson: '',
  contactType: '',
  date: '',
  duration: null,
  numberOfTrainedPersons: null,
  photo1: visitReportImageInitialState,
  photo2: visitReportImageInitialState,
  photo3: visitReportImageInitialState,
  qualification1: 0,
  qualification2: 0,
  qualification4: false,
  qualification5: false,
  qualification6: false,
  qualification7: false,
  qualification8: false,
  qualification9: false,
  qualification10: false,
  qualification11: false,
  qualification12: false,
  qualification13: false,
  qualification14: false,
  retrocession: false,
  promotion: false,
  promotionMonth: '',
  previousSellOut: 0,
  currentSellOut: 0,
  plvOptions: [],
  qualification26: 0,
  training1: 0,
  training2: 0,
  version: VISIT_REPORT_CURRENT_VERSION,
  localisation: null,
  pharmacySize: null,
  averagePassagesPerDay: null,
  positioningOfProductsOnShelf: null,
  brandPresence: [],
  doubleImplantation: null,
  numberOfVetoTablets: '',
  numberOfBiTablets: '',
  plvUse: [],
  numberOfTrainedPeople: null,
  numberOfCoursesOverLastYear: '',
  linearPartCalculation: '',
}

export const VISIT_DATE = 'date'
export const VISIT_VISIT_DATE = 'visitDate'
export const VISIT_DURATION = 'duration'
export const VISIT_CONTACT = 'contactPerson'
export const VISIT_ACTION_TYPE = 'contactType'
export const VISIT_ACTION_TYPE_ID = 'contactTypeId'
export const VISIT_OUTCOME = 'contactOutcome'
export const VISIT_OUTCOME_ID = 'contactOutcomeId'
export const VISIT_PERSON_ID = 'contactPersonId'
export const VISIT_PEOPLE_TRAINED = 'numberOfTrainedPersons'
export const VISIT_PEOPLE_NUMBER = 'numberOfPersons'
export const VISIT_ROTATION_1 = 'qualification1'
export const VISIT_ROTATION_2 = 'qualification2'
export const VISIT_ADVANTIX = 'qualification4'
export const VISIT_ADVANTAGE = 'qualification5'
export const VISIT_SARESSTO = 'qualification6'
export const VISIT_CLEMENT = 'qualification7'
export const VISIT_BIOCANINA = 'qualification8'
export const VISIT_RESP_70_AVANT = 'qualification9'
export const VISIT_RESP_50_AVANT = 'qualification10'
export const VISIT_YEUX_AVANT = 'qualification11'
export const VISIT_CHIENS_AVANT = 'qualification12'
export const VISIT_ACTION_LINEAIRE = 'qualification13'
export const VISIT_ACTION_PLV = 'qualification14'
export const VISIT_RETROCESSION = 'retrocession'
export const VISIT_PROMOTION = 'promotion'
export const VISIT_PROMOTION_MONTHS = 'promotionMonth'
export const VISIT_PREVIOUS_SELLOUT = 'previousSellOut'
export const VISIT_CURRENT_SELLOUT = 'currentSellOut'
export const VISIT_PLV_OPTIONS = 'plvOptions'
export const VISIT_COMBO_CHAT_AVANT = 'qualification26'
export const VISIT_COMMENT = 'comment'
export const VISIT_LOCALISATION = 'localisation'
export const VISIT_PHARMACY_SIZE = 'pharmacySize'
export const VISIT_AVERAGE_PASSAGES_PER_DAY = 'averagePassagesPerDay'
export const VISIT_POSITIONIN_ON_SHELF = 'positioningOfProductsOnShelf'
export const VISIT_DOUBLE_IMPLANTATION = 'doubleImplantation'
export const VISIT_NUMBER_OF_VETO_TABLETS = 'numberOfVetoTablets'
export const VISIT_NUMBER_OF_BI_TABLETS = 'numberOfBiTablets'
export const VISIT_PLV_USE = 'plvUse'
export const VISIT_BRAND_PRESENCE = 'brandPresence'
export const VISIT_TRAINING_NUMBER_OF_TRAINED_PEOPLE = 'numberOfTrainedPeople'
export const VISIT_NUMBER_OF_COURSES_OVER_LAST_YEAR =
  'numberOfCoursesOverLastYear'
export const VISIT_LINEAR_PART_CALCULATION = 'linearPartCalculation'

export const PHOTO_1 = 'photo1'
export const PHOTO_2 = 'photo2'
export const PHOTO_3 = 'photo3'
export const PHOTO_1_LABEL = 'Photo Rayon 1'
export const PHOTO_2_LABEL = 'Photo Rayon 2'
export const PHOTO_3_LABEL = 'Photo Rayon 3'
export const PHOTO_LABELS = [
  { value: PHOTO_1, label: PHOTO_1_LABEL },
  { value: PHOTO_2, label: PHOTO_2_LABEL },
  { value: PHOTO_3, label: PHOTO_3_LABEL },
]

export const PHOTO = 'Photo'

export const VISIT_REPORT_FIRST_PANEL_LIST: (keyof IVisitReport)[] = [
  VISIT_DATE,
  VISIT_DURATION,
  VISIT_CONTACT,
  VISIT_ACTION_TYPE,
  VISIT_OUTCOME,
  VISIT_RETROCESSION,
  VISIT_PEOPLE_TRAINED,
]

export const VISIT_REPORT_SECOND_PANEL_SELLOUT_LIST: (keyof IVisitReport)[] = [
  VISIT_PROMOTION,
  VISIT_PROMOTION_MONTHS,
  VISIT_PREVIOUS_SELLOUT,
  VISIT_CURRENT_SELLOUT,
]

export const VISIT_REPORT_SECOND_PANEL_V4_SELLOUT_LIST: (keyof IVisitReport)[] = [
  VISIT_POSITIONIN_ON_SHELF,
  VISIT_DOUBLE_IMPLANTATION,
  VISIT_NUMBER_OF_VETO_TABLETS,
  VISIT_NUMBER_OF_BI_TABLETS,
  VISIT_PLV_USE,
  VISIT_BRAND_PRESENCE,
  VISIT_TRAINING_NUMBER_OF_TRAINED_PEOPLE,
  VISIT_NUMBER_OF_COURSES_OVER_LAST_YEAR,
  VISIT_NUMBER_OF_VETO_TABLETS,
  VISIT_NUMBER_OF_BI_TABLETS,
  VISIT_LINEAR_PART_CALCULATION,
]

export const VISIT_REPORT_FOURTH_PANEL_LIST: (keyof IVisitReport)[] = [
  VISIT_LOCALISATION,
  VISIT_AVERAGE_PASSAGES_PER_DAY,
  VISIT_PHARMACY_SIZE,
]

export const VISIT_REPORT_ROTATION_LIST: (keyof IVisitReport)[] = [
  VISIT_ROTATION_1,
  VISIT_ROTATION_2,
]

export const VISIT_REPORT_IMAGE_LIST: (keyof IVisitReportImages)[] = [
  PHOTO_1,
  PHOTO_2,
  PHOTO_3,
]

export const VISIT_REPORT_SECOND_PANEL_LIST: (keyof IVisitReport)[] = [
  VISIT_CLEMENT,
  VISIT_ADVANTAGE,
  VISIT_ADVANTIX,
  VISIT_BIOCANINA,
  VISIT_SARESSTO,
]

export const VISIT_REPORT_RESP_70_LIST: (keyof IVisitReport)[] = [
  VISIT_RESP_70_AVANT,
]
export const VISIT_REPORT_RESP_50_LIST: (keyof IVisitReport)[] = [
  VISIT_RESP_50_AVANT,
]
export const VISIT_REPORT_YEUX_LIST: (keyof IVisitReport)[] = [VISIT_YEUX_AVANT]
export const VISIT_REPORT_CHIENS_LIST: (keyof IVisitReport)[] = [
  VISIT_CHIENS_AVANT,
]
export const VISIT_REPORT_COMBO_CHAT_LIST: (keyof IVisitReport)[] = [
  VISIT_COMBO_CHAT_AVANT,
]

export const VISIT_REPORT_SECOND_PANEL_REQUIRED: string[] = [
  VISIT_PLV_OPTIONS,
  VISIT_PROMOTION,
  VISIT_PROMOTION_MONTHS,
  VISIT_PREVIOUS_SELLOUT,
  VISIT_CURRENT_SELLOUT,
]

export const VISIT_REPORT_THIRD_PANEL_REQUIRED: string[] = []

// Visit report reset fields
export const VISIT_REPORT_RESET_EMPTY: (keyof IVisitReportForm)[] = [
  VISIT_VISIT_DATE,
  VISIT_OUTCOME_ID,
  VISIT_COMMENT,
  VISIT_PROMOTION_MONTHS,
]

export const VISIT_REPORT_RESET_EMPTY_ARRAY: (keyof IVisitReportForm)[] = [
  VISIT_RETROCESSION,
]

export const VISIT_REPORT_RESET_NULL: (keyof IVisitReportForm)[] = [
  VISIT_ACTION_TYPE_ID,
  VISIT_DURATION,
  VISIT_PEOPLE_NUMBER,
  VISIT_PREVIOUS_SELLOUT,
  VISIT_CURRENT_SELLOUT,
  VISIT_LOCALISATION,
  VISIT_PHARMACY_SIZE,
  VISIT_AVERAGE_PASSAGES_PER_DAY,
]

export const VISIT_REPORT_RESET_FALSE: (keyof IVisitReportForm)[] = []

export const EVENT_SECTION_APPOINTMENTS_COUNT = 1
export const EVENT_SECTION_DCR_CALLS_COUNT = 5

export const PROMOTION_MONTHS = [
  { name: 'Janvier', value: 'Janvier' },
  { name: 'Février', value: 'Février' },
  { name: 'Mars', value: 'Mars' },
  { name: 'Avril', value: 'Avril' },
  { name: 'Mai', value: 'Mai' },
  { name: 'Juin', value: 'Juin' },
  { name: 'Juillet', value: 'Juillet' },
  { name: 'Août', value: 'Août' },
  { name: 'Septembre', value: 'Septembre' },
  { name: 'Octobre', value: 'Octobre' },
  { name: 'Novembre', value: 'Novembre' },
  { name: 'Décembre', value: 'Décembre' },
]

export const LOCALISATION = [
  { labelId: 'form.field.localisationUrbaine.label', value: 1 },
  { labelId: 'form.field.localisationRurale.label', value: 2 },
  { labelId: 'form.field.localisationCentreCommercial.label', value: 3 },
]

export const AVERAGE_PASSAGES_PER_DAY = [
  { labelId: '100 - 200', value: 1, noTranslation: true },
  { labelId: '200 - 400', value: 2, noTranslation: true },
  { labelId: '400 - 600', value: 3, noTranslation: true },
  { labelId: '> 600', value: 4, noTranslation: true },
]

export const PHARMACYSIZE = [
  { labelId: 'form.field.pharmacySizeSmall.label', value: 1 },
  { labelId: 'form.field.pharmacySizeAverage.label', value: 2 },
  { labelId: 'form.field.pharmacySizeLarge.label', value: 3 },
  { labelId: 'form.field.pharmacySizeExtraLarge.label', value: 4 },
]

export const POSITIONING_OF_PRODUCTS_ON_SHELF = [
  {
    id: 'positioning1',
    labelId: 'positioningOfProductsOnShelfNotVisible',
    value: 1,
  },
  {
    id: 'positioning2',
    labelId: 'positioningOfProductsOnShelfBehingTheCounter',
    value: 2,
  },
  {
    id: 'positioning3',
    labelId: 'positioningOfProductsOnShelfFreeAccess',
    value: 3,
  },
]

export const PLV_USE = [
  {
    id: 'PLV1',
    labelId: 'plvUseLinear',
    value: 1,
  },
  {
    id: 'PLV2',
    labelId: 'plvUseNonLinear',
    value: 2,
  },
  {
    id: 'PLV3',
    labelId: 'plvUseNon',
    value: 3,
    disabled: false,
  },
]

export const BRAND_PRESENCE = [
  {
    id: 'brand1',
    labelId: 'brandPresenceBoehringerIngelheim',
    value: 1,
  },
  {
    id: 'brand2',
    labelId: 'brandPresenceElanco',
    value: 2,
  },
  {
    id: 'brand3',
    labelId: 'brandPresenceBiocanina',
    value: 3,
  },
  {
    id: 'brand4',
    labelId: 'brandPresenceClémentThékan',
    value: 4,
  },
  {
    id: 'brand5',
    labelId: 'brandPresenceAutres',
    value: 5,
  },
]
export const TRAINING = [
  { labelId: '0% - 25%', value: 1, noTranslation: true },
  { labelId: '25% - 50%', value: 2, noTranslation: true },
  { labelId: '50% - 75%', value: 3, noTranslation: true },
  { labelId: '75% - 100%', value: 4, noTranslation: true },
]
