import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILastVisitReport } from '../../interfaces/IVisitReports'

interface IClientLastVisitReportReducer {
  [id: string]: ILastVisitReport
}

const clientLastVisitReportReducer = createSlice({
  name: 'clientLastVisitReportReducer',
  initialState: {} as IClientLastVisitReportReducer,
  reducers: {
    setClientLastVisitReport: (
      state,
      { payload }: PayloadAction<IClientLastVisitReportReducer>
    ) => ({ ...state, ...payload }),
    replaceAllWithClientLastVisitReport: (
      state,
      { payload }: PayloadAction<IClientLastVisitReportReducer>
    ) => ({ ...payload }),
  },
})

export const clientLastVisitReportActions = clientLastVisitReportReducer.actions

export default clientLastVisitReportReducer.reducer
