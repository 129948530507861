import React, { ReactElement } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

function CheckboxFromGroup({
  name,
  labelId,
  id,
  customClass = '',
  value,
  defaultValues,
  register,
  wrapperClass,
  disabled = false,
}: {
  name: string
  labelId?: string
  id: string
  customClass?: string
  value?: number
  defaultValues?: number[]
  wrapperClass?: string
  disabled?: boolean
  register: (ref: HTMLInputElement) => void
}): ReactElement {
  return (
    <div className={`inputGroup ${customClass}`}>
      <div className={`inputCheckboxWrapper ${wrapperClass} dInlineFlex`}>
        <input
          name={name}
          type="checkbox"
          id={id}
          className="customCheckbox"
          value={value}
          ref={register}
          defaultChecked={!!(value && defaultValues?.includes(value))}
          disabled={disabled}
        />

        <DynamicFormattedMessage
          tag="label"
          id={`form.field.${labelId}.label`}
          htmlFor={id}
        />
      </div>
    </div>
  )
}

export default CheckboxFromGroup
