import { format } from 'date-fns'
import { DATE_REQUEST_FORMAT } from '../enums/common'
import { getJwtUserDetails } from './api'
import {
  ILastVisitReport,
  ILastVisitReportImagesOnly,
  IPlvData,
  IVisitReportForm,
  IVisitReportReset,
  TVisitReportCurrentVersion,
} from '../interfaces/IVisitReports'
import { IOption, ISelectOptionGroup } from '../interfaces/IFilters'
import { FROMATION_FLASH, OUI } from '../constants/form'
import {
  VISIT_REPORT_SECOND_PANEL_REQUIRED,
  VISIT_REPORT_THIRD_PANEL_REQUIRED,
  VISIT_ACTION_TYPE_1,
  VISIT_ACTION_TYPE_ID,
  VISIT_OUTCOME_ID,
  VISIT_DURATION,
  VISIT_PEOPLE_NUMBER,
  VISIT_PERSON_ID,
  VISIT_ACTION_LINEAIRE,
  VISIT_ACTION_PLV,
  VISIT_REPORT_CURRENT_VERSION,
  VISIT_REPORT_RESET_EMPTY,
  VISIT_REPORT_RESET_NULL,
  VISIT_REPORT_RESET_FALSE,
  VISIT_REPORT_RESET_EMPTY_ARRAY,
  VISIT_RETROCESSION,
  VISIT_PROMOTION,
  VISIT_PREVIOUS_SELLOUT,
  VISIT_CURRENT_SELLOUT,
} from '../constants/visitReport'

export const prepareRequestForNewVisitReport = (values: IVisitReportForm) => {
  const version: TVisitReportCurrentVersion = VISIT_REPORT_CURRENT_VERSION
  const actionType = values.contactTypeId
  const tabsEnabled = actionType === VISIT_ACTION_TYPE_1

  const defaultStrToBoolFields = [
    VISIT_ACTION_LINEAIRE,
    VISIT_ACTION_PLV,
    VISIT_RETROCESSION,
    VISIT_PROMOTION,
  ]

  const defaultStrToIntFields = [
    VISIT_PERSON_ID,
    VISIT_OUTCOME_ID,
    VISIT_DURATION,
    VISIT_ACTION_TYPE_ID,
    VISIT_PEOPLE_NUMBER,
    VISIT_PREVIOUS_SELLOUT,
    VISIT_CURRENT_SELLOUT,
  ]

  let strToIntFields = [...defaultStrToIntFields]
  let strToBoolFields = [...defaultStrToBoolFields]

  if (tabsEnabled) {
    strToIntFields = [...strToIntFields]
    strToBoolFields = [...strToBoolFields]
  }

  const formFields = [...strToBoolFields, ...strToIntFields]

  const bodyValues: any = []
  formFields
    .filter((key) => {
      const value = values[key as keyof typeof values]

      return (
        (!!value && (value as string | string[])?.length) ||
        key === VISIT_PERSON_ID
      )
    })
    .forEach((key) => {
      const value = values[key as keyof typeof values]

      bodyValues[key] = strToIntFields.includes(key)
        ? parseInt(value as string, 10)
        : Array.isArray(value)
        ? value[0] === OUI
        : value === OUI
    })

  if (values.duration) {
    bodyValues.duration = +values.duration * 60
  }

  if (values.promotionMonth) {
    bodyValues.promotionMonth = values.promotionMonth
  }

  bodyValues.comment = values.comment
  bodyValues.visitDate = format(new Date(values.visitDate), DATE_REQUEST_FORMAT)

  if (values.training) {
    const validTraining = values.training === FROMATION_FLASH ? 1 : 2
    bodyValues[`training${validTraining}`] = 1
  }

  if (values.localisation !== null) {
    bodyValues.localisation = Number(values.localisation)
  }

  if (values.pharmacySize !== null) {
    bodyValues.pharmacySize = Number(values.pharmacySize)
  }

  if (values.averagePassagesPerDay !== null) {
    bodyValues.averagePassagesPerDay = Number(values.averagePassagesPerDay)
  }

  if (values.positioningOfProductsOnShelf !== null) {
    bodyValues.positioningOfProductsOnShelf = Number(
      values.positioningOfProductsOnShelf
    )
  }

  if (values.numberOfTrainedPeople !== null) {
    bodyValues.numberOfTrainedPeople = Number(values.numberOfTrainedPeople)
  }

  if (values.doubleImplantation !== null) {
    bodyValues.doubleImplantation = values.doubleImplantation
  }

  if (values.numberOfVetoTablets !== null) {
    bodyValues.numberOfVetoTablets = Number(values.numberOfVetoTablets)
  }

  if (values.numberOfBiTablets !== null) {
    bodyValues.numberOfBiTablets = Number(values.numberOfBiTablets)
  }

  if (values.numberOfTrainedPeople !== null) {
    bodyValues.numberOfTrainedPeople = Number(values.numberOfTrainedPeople)
  }

  bodyValues.plvUse = values.plvUse?.map(Number)
  bodyValues.brandPresence = values.brandPresence?.map(Number)

  return {
    ...bodyValues,
    userId: getJwtUserDetails().id,
    version,
  }
}

/**
 * Used to convert javascript datetime object from new visit report form for redux state.
 */
export const convertFormDatetime = (visitReport: IVisitReportForm) => {
  if (visitReport.visitDate) {
    return { ...visitReport, visitDate: JSON.stringify(visitReport.visitDate) }
  }

  return {
    ...visitReport,
  }
}

export const prepareOptions = (options: IOption[]) => {
  return options.map(({ id, name }) => ({
    value: id.toString(),
    name,
  }))
}

export const dataURItoFile = (dataURI: string, filename: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([ia], filename, { type: mimeString })
}

export const prepareContactTypesOptions = (options: IOption[]) =>
  options.map(({ name, id }) => ({
    label: name,
    value: id.toString(),
  }))

export const preparePlvListOptions = (
  data: IPlvData[]
): ISelectOptionGroup[] => {
  return data.reduce((acc, { type, options }) => {
    const item: ISelectOptionGroup = {
      label: type,
      options: options.map(({ id, label }) => ({
        label,
        value: id.toString(),
      })),
    }
    if (!type) {
      acc.unshift(item)
    } else {
      acc.push(item)
    }
    return acc
  }, [] as ISelectOptionGroup[])
}

export const getActiveTab = (errorField: string) => {
  if (!errorField) return 0
  if (VISIT_REPORT_THIRD_PANEL_REQUIRED.includes(errorField)) return 2
  if (VISIT_REPORT_SECOND_PANEL_REQUIRED.includes(errorField)) return 1
  return 0
}

export const resetVisitReportFields: IVisitReportReset = (
  reset,
  setVisitDate,
  setValue
) => {
  reset()
  setVisitDate('')
  VISIT_REPORT_RESET_EMPTY.map((item) => setValue(item, ''))
  VISIT_REPORT_RESET_EMPTY_ARRAY.map((item) => setValue(item, []))
  VISIT_REPORT_RESET_NULL.map((item) => setValue(item, null))
  VISIT_REPORT_RESET_FALSE.map((item) => setValue(item, false))
}

export const prepareRequestForUploadImage = (
  values: IVisitReportForm,
  images: ILastVisitReport,
  type: string
) => {
  const image = values[type as keyof typeof values] as string
  const imageContent = image?.length && JSON.parse(image)
  const fileUploadAction = !imageContent.keepOld && imageContent.dataURL
  const keepOldAction = !imageContent.dataURL && imageContent.keepOld

  if (!imageContent?.dataURL?.length) return

  return {
    file: fileUploadAction
      ? dataURItoFile(imageContent.dataURL, imageContent.fileName)
      : undefined,
    type,
    keepOld: keepOldAction
      ? ((images as ILastVisitReportImagesOnly)[
          type as keyof ILastVisitReportImagesOnly
        ].key as string)
      : undefined,
  }
}
