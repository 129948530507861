import React, { ReactElement } from 'react'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

function RadioButton({
  name,
  labelId,
  translate,
  id,
  value,
  register,
  customClass = '',
  defaultValue,
  values,
  wrapperClass,
}: {
  name: string
  labelId: string
  translate: boolean
  id?: string
  value: number | string
  register: (ref: HTMLInputElement) => void
  customClass?: string
  defaultValue?: boolean
  values?: {}
  wrapperClass?: string
}): ReactElement {
  const inputId = id || `${name}-${value}`

  return (
    <div className={`inputGroup ${customClass}`}>
      <div className={`${wrapperClass} dInlineFlex alignItemsBaseline`}>
        <input
          name={name}
          type="radio"
          id={inputId}
          ref={register}
          className="inputRadioField"
          defaultChecked={defaultValue}
          value={value}
        />
        <span className="inputRadioControl" />
        {translate ? (
          <DynamicFormattedMessage
            tag="label"
            id={labelId}
            className="inputLabel"
            htmlFor={inputId}
            values={values}
          />
        ) : (
          <label className="inputLabel" htmlFor={inputId}>
            {labelId}
          </label>
        )}
      </div>
    </div>
  )
}

export default RadioButton
