import React, { FC, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import DynamicButtons from '../../form/DynamicButtons'
import {
  INFORMATION_TYPE_LIST,
  RADIO_OPTIONS_LIST,
} from '../../../constants/form'
import FormGroupDate from '../../form/FormGroupDate'
import {
  DATEPICKER_TIME_FORMAT,
  DYNAMIC_BUTTONS_INPUT_TYPE,
  RANGE_INPUT_DEFAULT,
  RANGE_INPUT_MAX,
  RANGE_INPUT_MIN,
  RANGE_INPUT_STEP,
} from '../../../enums/common'
import { ICustomTabForm } from '../../../interfaces/IVisitReports'
import SelectInput from '../../form/SelectInput'
import { useLoadData } from '../../../hooks/UseLoadData'
import { getContactPersons } from '../../../services/appointmentsService'
import {
  getContactOutcome,
  getContactTypes,
} from '../../../services/visitReportsService'
import WysiwygSection from '../../form/WysiwygSection'
import RangeStepSection from '../../form/RangeStepSection'
import DynamicFormattedMessage from '../../common/ui/DynamicFormattedMessage'
import {
  RANGE_PERSONS_DEFAULT,
  RANGE_PERSONS_MAX,
  RANGE_PERSONS_MIN,
  RANGE_PERSONS_STEP,
} from '../../../constants/userData'
import {
  dateInterval,
  getClosingHours,
  getDynamicButtonDefaultValue,
} from '../../../utils/helpers'
import {
  isGreaterThanOpeningHours,
  isSmallerThanClosingHours,
  getMinHour,
} from '../../../utils/appointments'
import { getClientContactPersons } from '../../../store/selectors/clientContactPersonsSelectors'
import {
  getContactOutcomesValue,
  getContactTypesValue,
} from '../../../store/selectors/sharedDataSelectors'
import Alerts from '../../alerts/Alerts'
import { IUsersOptions } from '../../../interfaces/IUsers'

export const VisitReportFirstPanel: FC<ICustomTabForm> = ({
  clientId,
  defaultData,
  visitDate,
  setVisitDate,
  setDisabled,
}) => {
  const {
    register,
    errors,
    control,
    watch,
    setValue,
    getValues,
  } = useFormContext()
  const watchFields = watch([
    'contactTypeId',
    'contactOutcomeId',
    'numberOfPersons',
    'training',
  ])

  const [visiteDuration, setVisiteDuration] = useState(RANGE_INPUT_DEFAULT)
  const [numberOfPersons, setNumberOfPersons] = useState(RANGE_PERSONS_DEFAULT)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const [maxDuration, setMaxDuration] = useState(RANGE_INPUT_MAX)
  const [hoursBeforeClose, setHoursBeforeClose] = useState(RANGE_INPUT_MAX)

  const contactTypes = useLoadData(() => getContactTypes(), {
    fetchFromRedux: true,
    reduxSelector: getContactTypesValue,
  })
  const contactOutcome = useLoadData(() => getContactOutcome(), {
    fetchFromRedux: true,
    reduxSelector: getContactOutcomesValue,
  })
  const contactPersons = useLoadData(() => getContactPersons(clientId), {
    fetchFromRedux: true,
    reduxSelector: getClientContactPersons,
    reduxStorePath: clientId?.toString(),
  })

  const matchType = contactOutcome?.data.find(
    (el: IUsersOptions) => el.value === watchFields.contactOutcomeId
  )
  const contactOutcomeValue = matchType?.name

  const invalidTime =
    !isGreaterThanOpeningHours(new Date()) ||
    !isSmallerThanClosingHours(new Date())
  const currentTime = invalidTime ? getMinHour(new Date()) : new Date()

  const date = {
    id: 'visitDate',
    value: visitDate.startDate !== '' ? visitDate.startDate : currentTime,
    label: 'form.field.visitDate',
    setValue: setVisitDate,
    classes: 'inputField inputDate datepickerLeft',
    error: errors.visitDate,
    displayTime: true,
    format: DATEPICKER_TIME_FORMAT,
    maxDate: new Date(),
    control,
    rules: {
      required: true,
    },
  }

  const visiteDurationFinal = {
    id: 'duration',
    name: 'duration',
    value: visiteDuration,
    setValue: setVisiteDuration,
    defValue: RANGE_INPUT_DEFAULT,
    step: RANGE_INPUT_STEP,
    minValue: RANGE_INPUT_MIN,
    maxValue: maxDuration,
    hasLabel: true,
    control,
    error: errors.duration,
    rules: {
      required: false,
    },
  }

  const numberOfPersonsFinal = {
    id: 'numberOfPersons',
    name: 'numberOfPersons',
    value: numberOfPersons,
    setValue: setNumberOfPersons,
    defValue: RANGE_PERSONS_DEFAULT,
    step: RANGE_PERSONS_STEP,
    minValue: RANGE_PERSONS_MIN,
    maxValue: RANGE_PERSONS_MAX,
    hasLabel: true,
    outputUnit: false,
    control,
    error: errors.numberOfPersons,
    rules: {
      required: false,
    },
  }

  useEffect(() => {
    const disabledTabs =
      watchFields.contactTypeId === '2' || watchFields.contactOutcomeId === '21'
    setDisabled(!!disabledTabs)
  }, [watchFields.contactTypeId, watchFields.contactOutcomeId, setDisabled])

  useEffect(() => {
    const formationContactOutcome =
      contactOutcomeValue && /formation/i.test(contactOutcomeValue)
    setIsVisible(!!formationContactOutcome)
  }, [contactOutcomeValue])

  useEffect(() => {
    const closingHours = date.value && getClosingHours(date.value as Date)

    setHoursBeforeClose(dateInterval(date.value, closingHours))
  }, [date.value])

  useEffect(() => {
    setMaxDuration(Math.min(hoursBeforeClose, RANGE_INPUT_MAX))
  }, [hoursBeforeClose])

  useEffect(() => {
    if (getValues(visiteDurationFinal.id) > maxDuration) {
      setValue(visiteDurationFinal.id, maxDuration)
      setVisiteDuration(maxDuration)
    }
  }, [maxDuration, setValue, visiteDurationFinal.id, getValues])

  useEffect(() => {
    if (!isVisible) {
      setValue(numberOfPersonsFinal.id, null)
      setNumberOfPersons(RANGE_PERSONS_DEFAULT)
      setValue('training', null)
    }
  }, [numberOfPersonsFinal.id, setValue, isVisible])

  return (
    <>
      <Alerts status={contactPersons.status} message={contactPersons.message} />
      <div className="row">
        <div className="colMd4 mb15">
          <FormGroupDate startDate={date} startDateOnly={true} />
        </div>
        <div className="colMd4 relative inputRange pl3 pr4">
          <RangeStepSection range={visiteDurationFinal} />
        </div>
        <div className="colMd4 mb15">
          <SelectInput
            id="contactPersonId"
            name="contactPersonId"
            options={contactPersons.data}
            register={register}
            defaultValue={defaultData.contactPersonId}
          />
        </div>
      </div>
      <div className="row my15">
        <div className="colMd3 mb15 pr0">
          <DynamicButtons
            optionsArray={contactTypes.data}
            register={register({ required: true })}
            error={errors.contactTypeId}
            name="contactTypeId"
            customClass="actionButtons"
            defaultValue={defaultData.contactTypeId || ''}
          />
        </div>
        <div className="colMd4 mb15 hasBorderRight">
          <SelectInput
            id="contactOutcomeId"
            name="contactOutcomeId"
            options={contactOutcome.data}
            register={register({ required: true })}
            error={errors.contactOutcomeId}
            defaultValue={defaultData.contactOutcomeId}
          />
        </div>
        <div className="colMd5 mb15">
          <div className="row mx0">
            <div className="colSm6 pl0 py05 dFlex alignItemsCenter">
              <DynamicFormattedMessage
                id="form.field.retrocession.label"
                className="textPrimary pl15"
              />
            </div>
            <div className="colSm6 py05 pr0 dFlex justifyContentEnd">
              <DynamicButtons
                optionsArray={RADIO_OPTIONS_LIST}
                register={register}
                name="retrocession"
                customClass="noLabel noWrap actionButtons alignRight"
                error={errors.retrocession}
                defaultValue={getDynamicButtonDefaultValue(
                  defaultData?.retrocession
                )}
                inputType={DYNAMIC_BUTTONS_INPUT_TYPE.checkbox}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`dNone ${isVisible ? 'row mb2' : ''}`}>
        <div className="col6 px0 mb15">
          <div className="col12">
            <DynamicButtons
              optionsArray={INFORMATION_TYPE_LIST}
              register={register({
                required: isVisible,
              })}
              name="training"
              defaultValue={defaultData.training1 || defaultData.training2}
              error={errors.training}
            />
          </div>
        </div>
        {isVisible && (
          <div className="colMd4">
            <RangeStepSection range={numberOfPersonsFinal} />
          </div>
        )}
      </div>
      <div className="row mb2">
        <div className="col12">
          <WysiwygSection
            control={control}
            errors={errors}
            defaultValue={defaultData.comment}
          />
        </div>
      </div>
    </>
  )
}
